import appConfigStoreModule from "@core/@app-config/appConfigStoreModule";
import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import VuexPersistence from "vuex-persist";

import auth from "./modules/auth";
import showProformModal from "./modules/showProformModal";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["auth", "showProformModal"],
});

export default new Vuex.Store({
  state: {
    loading: false,
    stateBaseLocationMap: null,
    stateOriginAddressMap: null,
    stateDestinationAddressMap: null,
    stateServiceTypeOrigin: null,
    stateTripComment: null,
    iconsDraggable: true,
    stateDriverCancelledEvent: false,
    stateDateTime: null,
    stateArrayMultiStop: [],
    stateMultiStopChangeInAddress: {
      changer: false,
      indexGiven: 0,
    },
  },
  mutations: {
    SET_LOADING: (state, value) => (state.loading = value),
    SET_BASE: (state, value) => (state.stateBaseLocationMap = value),
    SET_ORIGIN: (state, value) => (state.stateOriginAddressMap = value),
    SET_DESTINATION: (state, value) =>
      (state.stateDestinationAddressMap = value),
    SET_SERVICETYPEORIGIN: (state, value) =>
      (state.stateServiceTypeOrigin = value),
    SET_DRAGGABLE: (state, value) => (state.iconsDraggable = value),
    SET_DRIVER_CANCELLED_EVENT: (state, value) =>
      (state.stateDriverCancelledEvent = value),
    SET_DATETIME: (state, value) => (state.stateDateTime = value),
    SET_STATE_ARRAY_MULTISTOP: (state, newArray) => {
      state.stateArrayMultiStop = [...newArray];
    },
    SET_STATE_MULTISTOP_CHANGE_IN_ADDRESS: (
      state,
      stateMultiStopChangeInAddress
    ) => {
      state.stateMultiStopChangeInAddress = {
        ...state.stateMultiStopChangeInAddress,
        ...stateMultiStopChangeInAddress,
      };
    },
    SET_STATE_TRIP_COMMENT: (state, value) => (state.stateTripComment = value),
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    setBase({ commit }, payload) {
      commit("SET_BASE", payload);
    },
    setOrigin({ commit }, payload) {
      commit("SET_ORIGIN", payload);
    },
    setDestination({ commit }, payload) {
      commit("SET_DESTINATION", payload);
    },
    setServiceTypeOrigin({ commit }, payload) {
      commit("SET_SERVICETYPEORIGIN", payload);
    },
    setDraggable({ commit }, payload) {
      commit("SET_DRAGGABLE", payload);
    },
    setDriverCancelledEvent({ commit }, payload) {
      commit("SET_DRIVER_CANCELLED_EVENT", payload);
    },
    setDateTime({ commit }, payload) {
      commit("SET_DATETIME", payload);
    },
    setStateArrayMultiStop({ commit }, payload) {
      commit("SET_STATE_ARRAY_MULTISTOP", payload);
    },
    setStateMultiStopChangeInAddress({ commit }, payload) {
      commit("SET_STATE_MULTISTOP_CHANGE_IN_ADDRESS", payload);
    },
    setStateTripComment({ commit }, payload) {
      commit("SET_STATE_TRIP_COMMENT", payload);
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    showProformModal,
  },
  plugins: [vuexLocal.plugin],
});
